<template>
    <div v-htmltit data-title="余额明细">
        <go-back-btn></go-back-btn>
        <div class="price_box">
            <div class="price_title">
                <div class="price_title_item">日期</div>
                <div class="price_title_item">操作</div>
                <div class="price_title_item">余额变动</div>
                <div class="price_title_item">结余</div>
            </div>
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
                <van-list
                    class="price_list"
                    v-model="loading"
                    :finished="finished"
                    finished-text="已经到底了"
                    @load="onLoad">
                    <div class="price_item" v-for="(item, index) in priceDetail" :key="index">
                        <div class="price_item_item border_left">{{ formatData(item.time,'Y/M/D h:m:s') }}</div>
                        <div class="price_item_item" v-if="item.interalGetOrUseType == 'INTEGRAL_WEIGHT_RECHARGE'">
                            <span v-if="item.interalType == 1">称重品返还</span>
                            <span v-if="item.interalType == 2">称重品折扣</span>
                        </div>
                        <div class="price_item_item" v-if="item.interalGetOrUseType != 'INTEGRAL_WEIGHT_RECHARGE'" >{{ integralType[item.interalGetOrUseType] }}</div>
                        <div class="price_item_item" :class="item.interalType === 1?'green':'red'">{{ item.interalType === 1 ? "+" : "-"}} {{ item.interalQuantity.toFixed(2) }}</div>
                        <div class="price_item_item">{{item.nowInteral.toFixed(2)}}</div>
                    </div>
                </van-list>
            </van-pull-refresh>
        </div>
    </div>
</template>
<script>
import './index.less'
import goBackBtn from "@/view/Public/goBackBtn"
import { integralRecord } from "@/apis/api"
import { formatTime } from "@/libs/utils"
export default {
    name:"price-detail",
    components: { goBackBtn },
    data() {
        return {
            page:1,
            pageSize:10,
            isLoading:false,//下拉刷新loading
            loading: false,//上拉加载列表的数据
            finished: false,//上拉加载列表的数据
            priceDetail: [],
            integralType: {
                "INTEGRAL_GET_INVITATION": "邀请赠送",
                "INTEGRAL_GET_ENTER": "入驻赠送",
                "INTEGRAL_USE_DOCUMENT_ORDER": "订单消费",
                "INTEGRAL_GAT_INCREMENT": "云播时长兑换",
                "INTEGRAL_GET_DOCUMENT_ORDER": "订单赠送",
                "INTEGRAL_RETURN_DOCUMENT_ORDER": "取消订单返还",
                "INTEGRAL_RECHARGE":"余额充值",
                "INTEGRAL_GET_DOCUMENT_ACTIVITY": "活动赠送",
                "INTEGRAL_GET_CUSTOMER_RECHARGE": "客户余额充值",
                "INTEGRAL_ADD_CUSTOMER_QUANTITY": "余额补录",
                "INTEGRAL_DELETE_CUSTOMER_QUANTITY":"余额清零",
                "INTEGRAL_GET_GROUP_PURCHASE":'客户付款',
                "INTEGRAL_GET_PHYSICAL_CARD":'实体卡充值',
                "INTEGRAL_GET_MONEY_DOCUMENT_TYPE_GETMONEY":'订单消费',
                "INTEGRAL_GET_MONEY_DOCUMENT_TYPE_PAYMENT":'退款'
			}
        }
    },
    methods: {
        formatData(number, format){
            return formatTime(number, format)
        },
        onRefresh() {
            this.page = 1
            this.finished = false;
            this.loading = true;
            this.priceDetail = []
            this.onLoad()
        },
        onLoad() {
            let data = {
                integralType:1,
                page:this.page,
                pageSize:this.pageSize
            }
            integralRecord(data).then(res=>{
                if(res.code == 0){
                    if (this.isLoading) {
                        this.priceDetail = [];
                        this.isLoading = false;
                    }
                    this.page++;
                    let newList = res.res.integralList;
                    newList.map(item=>{
                        item.orderTime = formatTime(item.orderTime,'Y-M-D h:m:s')
                    })
                    if(newList.length < this.pageSize){
                        this.finished = true; 
                    }
                    this.priceDetail = this.priceDetail.concat(newList);
                    // 加载状态结束
                    this.loading = false;
                    // 数据全部加载完成
                    if(this.priceDetail.length >= res.res.count){
                        this.finished = true;
                    }
                }else{
                     this.$toast(res.msg)
                }
            })
        }
    }
}
</script>